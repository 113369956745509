/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { Link } from "react-router-dom";
import Axios from 'axios';
import { diffInDays } from './utility';
import MyModal from './common/MyModal';

const CoachingListAdmin = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const [itemEvents, setItemEvents] = useState([]);


    const fetchClient = () => {

        coreContext.fetchSelf(authContext.itemId);
        coreContext.fetchSahyogEvents(authContext.ownerId,'active');
        fetchEvents();
    }

    useEffect(fetchClient, []);

    const fetchEvents = () => {

        Axios.get('/jnv-sahyog-events-my-contributions', { params: { itemId: authContext.itemId } }).then(response => {
            console.log(response.data.events);
            const eids = [];
            const events = [];
            const evs = response.data.events;
            evs.forEach(event => {
                if (!eids.includes(event.id)) {
                    eids.push(event.id);
                    events.push(event);
                }
            });
            setItemEvents(events);

        }).catch(err => {


        });

    }

    const renderEvents = () => {
        if (itemEvents.length > 0) {
            return itemEvents.map((se, index) => {
                let purl = 'https://'+se.paymentUrl;
                return <tr key={++index}>
                    <th>{index}</th>
                    <td>{se.comments}</td>
                    <td>{new Date(se.createdAt).toDateString()}</td>
                    <td>{se.amount}</td>
                    <td><a target="_blank" rel="noopener noreferrer" href={purl}><img style={{height: '50px'}} className='img-thumbnail' alt='receipt' src={purl}/></a></td>
                </tr>
            });
        }
        else {
            return (<tr>
                <th>No contributors currently !</th>
            </tr>)
        }
    }



    const bookingStatus = () =>{
        if(coreContext.client.smst_payment_done === 'no'){
            return <div>
                <h6><Link className="text-danger" to="/upload-receipt">Complete Registration (Upload Payment Slip)</Link></h6>
            </div>;
        } else if(coreContext.client.smst_booking_confirmed === 'no'){
            return <div>
                <h6>We are in receipt of your payment slip, we will shortly register you for the event and confirm.<Link className="text-danger" to="/upload-receipt">View receipt</Link></h6>
            </div>;
        } else {
            const diff = 365 - (diffInDays(new Date(coreContext.client.membership_start_date), new Date()));
            const renewalReminderMessage = diff < 0 ? 'Your subscription has expired, please deposit your vyavastha shulk again !' : diff < 30 ? 'Your subscription expires in ' + diff + ' days !' : '';

            if (diff < 30) return <div> <h4 class="text-danger">{renewalReminderMessage}</h4> <h6><Link className="text-danger" to="/upload-receipt">Complete Registration (Upload Payment Slip)</Link></h6></div>

            return <div>
        <h6 className="text-success">Congratulations, You have successfully registered yourself with us. <a className="text-danger" href="https://swabhimanjnv.in">Click here</a> to visit the Website.</h6>

                {/* <MyModal show={true} modalBody="hello" /> */}
    </div>;
        }
    }

    const abstractStatus = () =>{
       
            return <div>
                <h6><Link className="text-danger" to="/profile">View Your Profile</Link></h6>
            </div>
    }

    const getTotalAmount = () => {
        let tam = 0;
        itemEvents.forEach(ie => {
            tam += parseInt(ie.amount);
        });

        return tam;

    }
    const contributionStatus = () =>{
       
            return   <table className="table table-sm table-bordered">

            {itemEvents.length > 0 ? <thead><th></th>
                <th>Event</th>
                <th>Payment Date</th>
                <th>Amount (Rs.)</th> 
                </thead> : ''}
            <tbody>
                {renderEvents()}
            </tbody>
            {itemEvents.length > 0 ?
            <tfoot>
                <tr>
                    <th colspan='3'>Total</th>
                    <th>{getTotalAmount()}</th>
                </tr>
            </tfoot>
            : ''}
        </table>
    }

    const renderCertificate = (certificate) => {
       
        if (certificate){
            if(certificate.includes('.pdf'))
            return <div className="col-md-6"> <a rel="noopener noreferrer" target="_blank" href={`https://${certificate}`}><img style={{ padding: 5 }} className="img-fluid" src="https://node-websites.siddhantait.com/images/pdfimage.png" alt='Certificate' /></a></div>
            else
            return <div className="col-md-6"> <img style={{ padding: 5 }} className="img-fluid" src={`https://${certificate}`} alt='Certificate' /></div>
        }
            return <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src='https://node-websites.siddhantait.com/images/file-not-found-icon-png.png' alt='Certificate' /></div>
    }

    const renderActiveEvents = () => {
        if (coreContext.sahyogEvents.length > 0) {
            return coreContext.sahyogEvents.map((se, index) => {

                return <tr key={++index}>
                    <th>{index}</th>
                    <td>{se.item_name}</td>
                    <td>{se.district}</td>
                    <td>{se.address}</td>
                    <td>{se.membership_start_date}</td>
                    <td>{se.comments}</td>
                    <td>{renderCertificate(se.imageUrl)}</td>
                    <td> <Link style={{color:'red', textDecoration: 'none'}} to={`/contribute-in-sahyog/${se.id}`}>Contribute</Link></td>
                </tr>
            });
        }
        else {
            return ( <tr>
                <th>No active events currently !</th>
            </tr>)
        }
    }


    return (    
       <div className='card'>
        <div className='row'>
            <div className='col-md-6'>
        <div className='card card-body'>
           <h5 className='card-title'>Registration Status</h5>
{bookingStatus()}
        </div>
        </div>
            <div className='col-md-6'>
            <div className='card card-body'>
            <h5 className='card-title'>My Profile</h5>
            {abstractStatus()}
        </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-6'>
        <div className='card card-body'>
           <h5 className='card-title'>Financial Documents</h5>
<a target="_blank" href="https://swabhimanjnv.in/financial-documents">View</a>
        </div>
        </div>
            <div className='col-md-6'>
            <div className='card card-body'>
            <h5 className='card-title'>My Contributions</h5>
            {contributionStatus()}
        </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-6'>
        <div className='card card-body'>
           <h5 className='card-title'>Sahyog Events <Link className="btn btn-link" to='sahyog-events'>View All</Link></h5>
           <table className="table table-sm table-bordered">
           
                {coreContext.sahyogEvents.length > 0 ?  <thead><th></th>
                    <th>Name</th>
                    <th>District</th>
                    <th>Address</th>
                    <th>Joining</th>
                    <th>Details</th>  </thead> : ''}
           <tbody>
           {renderActiveEvents()}
           </tbody>
           </table>
        </div>
        </div>
            
        </div>
       </div>
    );

}

export { CoachingListAdmin };